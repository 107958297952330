import './style.scss';

import $ from 'jquery';
import _ from 'lodash';
import bowser from 'bowser';
import IEFix from './ie-fix';

$(function() {
  // TODO: 需要支持 turbolink
  setTimeout(function() {
    $('.tp-test-paper form').not('[data-alert=false]').each(function() {
      const originValues = $(this).serialize();
      $(this).on('submit', function(event) {
        /* eslint-disable no-alert */
        if (originValues === $(this).serialize()) {
          alert('请先填写课件内容，不能提交白卷!');
          event.preventDefault();
          return;
        }

        /* eslint-disable no-confirm */
        if (!confirm('该课件提交后将不可修改，是否确认提交？')) {
          event.preventDefault();
          return;
        }

        $(this).find('textarea, :text').val(function() {
          return _.trim(this.value);
        });
      });
    });
  }, 3000);
});

$(document).on('content:loaded', function(event) {
  if (bowser.msie || bowser.edge) {
    IEFix.fixAll(event.target);
  }
});
