import $ from 'jquery'
import * as Vue from 'vue'
import EntryText from '@vendor/leaf-components/src/components/entry/EntryText.vue'
import EntryHeading from '@vendor/leaf-components/src/components/entry/EntryHeading.vue'
import EntrySuite from '@vendor/leaf-components/src/components/entry/EntrySuite.vue'
import EntryQuestion from '@vendor/leaf-components/src/components/entry/EntryQuestion.vue'

import 'bootstrap/dist/css/bootstrap.css'
import '../frontend/coursewares.scss'
import '../frontend/components/shadowmeld'
import '../frontend/pages/test-paper'
import '../frontend/print.scss'

const components = {
  EntryQuestion,
  EntryHeading,
  EntrySuite,
  EntryText
}

$(function() {
  $('.vue-component').each(function(index, element) {
    const props = $(element).data('props')
    const name = $(element).data('type')
    const component = components[name]
    const template = element.innerHTML
    Vue.createApp({
      components: {
        TheComponent: component
      },
      template: `
      <TheComponent v-bind="compProps">
        ${template}
      </TheComponent>
      `,
      data (){
        return { compProps: props }
      }
    }).mount(element)
  })
})
