import $ from 'jquery'

function fixZhEm(root) {
  $(root).find('.tp-zh-em').each(function() {
    const result = []
    for (let i = 0; i < this.childNodes.length; i += 1) {
      const node = this.childNodes[i]
      if (node.nodeType === 3) {
        result.push(node.textContent.replace(/(.)/g, '<span class="tp-zh-em-ie">$1</span>'))
      } else {
        result.push($('<span class="tp-zh-em-ie"></span>').append(node))
      }
    }

    $(this).removeClass('tp-zh-em').html(result)
  })
}

function fixIeDetails(root) {
  $(root).find('details').each(function() {
    const $details = $(this)
    const $summary = $details.find('summary')
    $summary.click(function(event) {
      event.preventDefault()

      if ($details.hasClass('open')) {
        $details.removeClass('open')
        $details.css('height', $summary.innerHeight())
      } else {
        $details.addClass('open')
        $details.css('height', 'auto')
      }
    })

    $details.removeClass('open')
    $details.css('height', '1.5em')
  })
}

const IEFix = {
  fixAll(root) {
    fixZhEm(root)
    fixIeDetails(root)
  }
}

export default IEFix
